import { useState } from "react";
import CommonPageHeader from "../../common/components/headers/CommonPageHeader";

const ExpandedImages = () => {
    return (
        <div>
          


        </div>
    );
};

export default ExpandedImages;
