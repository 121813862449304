import Assets from "../../assets";



const Marketplacepopup = () => { 
    return (
        <div>
            <div className=" pt-10 font-normal px-10">All listings created on Business Bosses must meet the following guidelines or the listing and the user account will be deleted and banned permanently.</div>
            <div className="text-center text-black font-bold pt-10 text-lg">GUIDELINES</div>
            <div className="pt-10 px-10">🚫 Weapons, ammunitions, explosives, and hazardous goods listings are not allowed</div>
            <div className="px-10">🚫 Human trafficking, prostitution, escort, sexual services or pornographic listings are not allowed</div>
            <div className="px-10">🚫 Illegal Drugs, Prescription or Recreational Drugs, Other Drug paraphernalia and alcohol listings is not allowed</div>
            <div className="px-10">🚫 You cannot list stolen goods and your listing must not infringe intellectual property rights of a third-party (e.g. copyright)</div>
            <div className="px-10">🚫 Selling animals and posting about animals for adoption listings are not allowed</div>
            <div className="px-10">✅ Ensure any image and description are honest and fair.</div>
            <div className="px-10 pb-10">✅ Business Bosses does not offer an in-built payment feature yet, it's down to you to choose a payment provider that offers buyer protection (e.g PayPal or escrow)</div>

           

          
           
        </div>
    );
};

export default Marketplacepopup;


