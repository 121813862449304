


const Bossoftheweekpopup = () => {
    return (
        <div>
            <div className="text-center text-black font-bold pt-10 text-lg">BOSS UP CHALLENGE</div>
            <div className="text-center pt-5 text-primary font-bold px-10">Become 'Boss of the week' and get free promotion for a week</div>
            <div className="px-5 pt-5 pb-20">
                <div className="text-sm lg:text-base">
                    <div>● Start a topic in Boss up challenge group, introduce yourself and business</div>
                    <div>● The user with highest post likes in a week, will become the winner of 'Boss of week'</div>
                    <div>● The winner will be promoted on the App for a week</div>
                    <div>● Users can enter the challenge once every 12 weeks</div>
                </div>

            </div>

        </div>
    );
};

export default Bossoftheweekpopup;


