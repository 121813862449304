class TimeFormat {
    static ONE_SECOND: number = 1000;
    static ONE_MINUTE: number = 60000;
    static ONE_HOUR: number = 3600000;
    static ONE_DAY: number = 86400000;
    static ONE_WEEK: number = 604800000;
    static ONE_MONTH: number = 1000 * 60 * 60 * 24 * 30;
}


export default TimeFormat